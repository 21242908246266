import $ from 'jquery';
import 'slick-carousel/slick/slick';
import WOW from 'wow.js/dist/wow.js';
import "./modal.js";
$(function () {
    $(window).on("load resize",function () {
        setTimeout(function(){
            var left_close = $(".hamberger").offset().left;
            $(".close-button").css({
                "position": "fixed",
                "left":left_close,
            });
        },200);
        if ($(window).width() < 768) {
            if($(".slider-vertical")){
                $(".slider-vertical").not('.slick-initialized').slick({
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                });
            }
        }else{
            if($(".slider-vertical")){
                if ($('.slider-vertical').hasClass('slick-initialized')) {
                    $('.slider-vertical').slick('unslick');
                }
            }
        }
    })
    // Hamberger menu
    $(".hamberger").on("click",function (e) {
        e.preventDefault();
        $(".nav-bar").fadeIn();
        var left = $(".contact").offset().left;
        $(".contact").css({
            "position": "fixed",
            "left":left,
            "margin-left":0
        });

        var left_close = $(".hamberger").offset().left;
        $(".close-button").css({
            "position": "fixed",
            "left":left_close,
        });

        $("body").css({
            "position": "fixed",
            "overflow-y": "scroll",
        });

    })
    $(window).on("load resize",function () {
        setTimeout(function(){
            var left_close = $(".hamberger").offset().left;
            $(".close-button").css({
                "position": "fixed",
                "left":left_close,
            });
        },200);
    })
    $(".close-button").on("click",function (e) {
        e.preventDefault();
        $(".nav-bar").fadeOut();
        $(".contact").attr("style","");
        $("body").attr("style","");
    })

    // Dropdown menu header
    if ($(window).width() < 768) {
        $(".inner-nav ul .nav-item").on("click",function (e) {
            if($(e.target).is(".nav-item")) {
                e.preventDefault();
                if ($(this).hasClass("close")) {
                    $(this).find(".menu-childen").slideDown();
                    $(this).addClass("open").removeClass("close");
                } else {
                    $(this).find(".menu-childen").slideUp();
                    $(this).addClass("close").removeClass("open");
                }
            }
        });

        // Dropdown menu footer
        $(".menu-item").on("click",function (e) {
            if(!$(e.target).closest("a").length > 0){
                e.preventDefault();
                if($(this).hasClass("close")){
                    $(this).find(".nav-childen").slideDown();
                    $(this).addClass("open").removeClass("close");
                }else{
                    $(this).find(".nav-childen").slideUp();
                    $(this).addClass("close").removeClass("open");
                }
            }
        });
    }

    // Wow for about & solution
    if($('.box-about-solution').length) {
        var wow = new WOW();
        wow.init();
    }
    if($('.box-works li').length) {
        var wow = new WOW();
        wow.init();
    }

    $(".page-menu ul li a").on("click",function (e) {
        var target = $(this).attr("href");
        if (target[0] != "#") {
            return true
        }
        e.preventDefault();
        let offset = ['#sales', '#rental', '#studio'].indexOf(target) > -1
            ? 10
            : 80;
        $('html, body').animate({
            scrollTop : $(target).offset().top - offset
        }, 800);
    })
    if($(".dg-slick")){
        $(".dg-slick").slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        infinite: true,
                        arrows: true,
                    }
                }
            ]
        });
    }
    if($('.slider-equip')){
        $('.slider-equip').slick({
            speed: 500,
            arrows: true,
            slidesToScroll: 1,
            infinite: true,
            centerMode: true,
            centerPadding: '140px',
            slidesToShow: 4,
            dots: true,
            responsive: [
                {
                    breakpoint: 1440,
                    settings: {
                        centerMode: true,
                        centerPadding: '12.28vw',
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        centerMode: true,
                        centerPadding: '21.2vw',
                        slidesToShow: 1
                    }
                },

            ]
        });
    }
})

// Back to top
$(window).scroll(function(){
    // Show button after 100px
    var showAfter = 100;
    if ($(this).scrollTop() > showAfter ) {
        $('.back-to-top').fadeIn();
    } else {
        $('.back-to-top').fadeOut();
    }
});

//Click event to scroll to top
$('.back-to-top').click(function(){
    $('html, body').animate({scrollTop : 0},800);
    return false;
});

// Resize window for hambeger + close button
$(window).on("load resize",function () {
    var left = $(".contact").offset().left;
    $(".contact").css({
        "position": "fixed",
        "left": left,
    });

    var left_close = $(".hamberger").offset().left;
    $(".close-button").css({
        "position": "fixed",
    });

    $(".contact").attr("style", "");
    // $("body").attr("style", "");

})

//
// $(function () {
//    $('.slider').slick({
//         dots: true,
//         infinite: false,
//         speed: 500,
//         autoplaySpeed: 5000,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         autoplay: true,
//         fade: true,
//         cssEase: 'linear'
//     });
//
// });