import $ from 'jquery';

$(function () {
    $("a, area").on("click",function (e) {
        var target = $(this).attr("href");
        if(target !== "" && target !== "#" && target.indexOf("/") < 0 && $(target).is(".modal-content")){
            e.preventDefault();
            if($(target).find("img")){
                var src = $(target).find("img").attr("data-src");
                $(target).find("img").attr("src",src);
            }
            if($(target).find("iframe")){
                var iframesrc = $(target).find("iframe").attr("data-src");
                $(target).find("iframe").attr("src",iframesrc);
            }

            setTimeout(function (){
                $("body").css("overflow","hidden");
                $(target).fadeIn("slow").closest(".modal").addClass("show");
            },300)
        }
    });
    $(".modal, .modal .close-modal").on("click",function (e) {
        if($(e.target).is(".modal") || $(e.target).is(".close-modal")){
            e.preventDefault();
            var src =  $(".modal.show .modal-content:visible iframe").attr("src");
            $(".modal.show .modal-content:visible iframe").attr("src","");
            $(".modal.show .modal-content:visible iframe").attr("src",src);
            $(".modal-content").attr("style","");
            $(".modal").removeClass("show");
            $("body").attr("style","");
        }
    });

})
